@import '@progress/kendo-theme-material/scss/input/_variables.scss';
@import '@progress/kendo-theme-material/scss/floating-label/_variables.scss';

$floating-label-offset-x: $input-padding-x;
$floating-label-offset-y: calc($input-font-size * $kendo-input-line-height * 0.5 + $kendo-input-padding-y);

@import '@progress/kendo-theme-material/scss/floating-label/_index.scss';

.k-form-field {
	&.kpa-required {
		.k-floating-label-container,
		.k-floating-label-container:hover,
		.k-floating-label-container:focus-within {
			background-color: rgba($primary, 0.08);
		}
	}

	&.k-form-field-error,
	&.k-form-field-error.kpa-required {
		.k-floating-label-container,
		.k-floating-label-container:hover,
		.k-floating-label-container:focus-within {
			background-color: rgba($error, 0.08);
		}
	}

	.k-floating-label-container {
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;

		.k-label {
			overflow: inherit;
		}
	}

	&:not(.kpa-required):not(.k-form-field-error):not(.k-form-field-disabled) .k-floating-label-container {
		&:hover,
		&:focus-within {
			background-color: transparent;
		}
	}

	.k-form-hint,
	.k-form-error {
		padding-left: $input-padding-x;
		padding-right: $input-padding-x;
	}
}

.k-floating-label-container {
	.k-input-prefix,
	.k-input-suffix {
		position: relative;
		bottom: 8px;
	}
}

kendo-floatinglabel {
	width: 100%;
	label {
		padding-left: 5px;
		font-size: 14px !important;
	}

	&.required-field.k-invalid,
	&.required-field-error.k-invalid {
		background-color: rgba(237, 81, 55, 0.08) !important;
		border-bottom-color: rgba(237, 81, 55, 0.08) !important;
		border-width: 2px;
		&.k-focus {
			box-shadow: 0 0 0 1px #ed5137;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}
	}
	&.k-focus:not(.k-invalid) {
		textarea,
		input {
			border-bottom-color: #1c75bc !important;
			border-bottom-width: 2px;
		}
		label {
			color: #1c75bc !important;
		}
	}
	&.k-focus, &:not(.k-empty):not(.k-focus), &.k-focus:not(.k-invalid) {
		&.small-label {
			label {
				font-size: small;
			}
		}
		&.medium-label {
			label {
				font-size: medium;
			}
		}
		&.large-label {
			label {
				font-size: large;
			}
		}
	}
}
