$calendar-today-nav-text: $primary;
$calendar-today-nav-hovered-text: $primary-hovered;

.k-datepicker .k-select .k-icon.k-i-calendar::before {
	@include font-awesome-light;
	content: '\f783';
}

.k-form-field {
	.k-floating-label-container {
		.k-picker-wrap > .k-select {
			position: relative;
			bottom: calc($kendo-input-padding-x / 2);
		}
	}
}
