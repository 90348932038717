@import '@progress/kendo-theme-material/scss/typography/_index.scss';

$kendo-button-text: $primary;
$kendo-button-bg: transparent;
$kendo-button-padding-x: calc($kendo-font-size * 2);
$kendo-button-padding-y: calc($kendo-font-size * 5 / 7);
$outline-button-border-width: 1px;
$kendo-chip-filled-bg: $primary;
$kendo-chip-filled-text: white;
$kendo-chip-filled-hovered-bg: $primary-hovered;
$kendo-chip-filled-focused-bg: $primary-focused;
$kendo-chip-filled-success-bg: $success;
$kendo-chip-filled-success-text: white;
$kendo-chip-filled-info-bg: $info;
$kendo-chip-filled-info-text: white;
$kendo-chip-filled-warning-bg: $warning;
$kendo-chip-filled-warning-text: white;
$kendo-chip-filled-error-bg: $error;
$kendo-chip-filled-error-text: white;

@import '@progress/kendo-theme-material/scss/button/_index.scss';
@import '@progress/kendo-theme-material/scss/chip/_index.scss';

// todo: figure out what icon size was
$dropdown-icon-padding-calc: calc(
	($kendo-button-font-size * $kendo-button-line-height - 14px) / 2 + $kendo-button-padding-y - $outline-button-border-width
);
$dropdown-icon-margin-x-calc: $kendo-button-padding-x - $outline-button-border-width;

.k-button {
	font-weight: 600;
}

.k-button.k-primary.k-flat.kpa-primary:not(.k-state-disabled) {
	border-color: $primary !important;
	color: $primary;
	background: $secondary !important;
}

.k-button.k-primary.k-flat.kpa-primary:not(.k-disabled) {
	border-color: $primary !important;
	color: $primary;
	background: $secondary !important;
}

.k-button-group.kpa-button-group {
	box-shadow: none;

	> .k-button.k-outline {
		border-right-width: 1px;
		border-left-width: 0;
		padding-left: $kendo-button-padding-x;

		&.k-group-start {
			padding-left: calc(#{$kendo-button-padding-x - 1});
			border-left-width: 1px;
		}
	}

	.k-button-flat-primary,
	.k-button-flat.k-primary,
	.k-button.k-flat.k-primary {
		border-color: $primary !important;
		color: $primary;
		background: $secondary !important;

		&:disabled,
		&.k-state-disabled {
			@include fill($kendo-button-disabled-text, $kendo-button-disabled-bg);
			@include box-shadow(none);
			opacity: 1;
		}

		&.k-disabled {
			@include fill($kendo-button-disabled-text, $kendo-button-disabled-bg);
			@include box-shadow(none);
			opacity: 1;
		}

		&:active:not(.k-state-focused):not(:hover),
		&.k-state-active:not(.k-state-focused):not(:hover) {
			&::before {
				opacity: 0;
			}
		}
	}
}

.k-dropdown-button.kpa-dropdown-button {
	direction: rtl;
	unicode-bidi: isolate;

	.k-button-icontext {
		direction: rtl;
		unicode-bidi: isolate;

		.k-icon {
			position: relative;
			padding: $dropdown-icon-padding-calc;
			margin: calc(
				#{$dropdown-icon-padding-calc * -1} #{$dropdown-icon-margin-x-calc * -1} #{$dropdown-icon-padding-calc * -1} $dropdown-icon-margin-x-calc
			);
			border-left: 1px solid $primary;
			border-radius: 0 $border-radius $border-radius 0;
			z-index: 10;
		}
	}

	&.k-state-focused .k-button-icontext .k-icon {
		color: white;
		background-color: $primary;
	}
}

.k-chip-filled {
	&,
	&.k-chip-warning,
	&.k-chip-error,
	&.k-chip-success,
	&.k-chip-info {
		.k-remove-icon {
			color: white;
		}
	}

	&.k-chip-success {
		&:hover,
		&.k-chip-hover {
			background-color: $success-hovered;
		}

		&:focus,
		&.k-chip-focused {
			background-color: $success-focused;
		}
	}

	&.k-chip-warning {
		&:hover,
		&.k-chip-hover {
			background-color: $warning-hovered;
		}

		&:focus,
		&.k-chip-focused {
			background-color: $warning-focused;
		}
	}

	&.k-chip-error {
		&:hover,
		&.k-chip-hover {
			background-color: $error-hovered;
		}

		&:focus,
		&.k-chip-focused {
			background-color: $error-focused;
		}
	}

	&.k-chip-info {
		&:hover,
		&.k-chip-hover {
			background-color: $info-hovered;
		}

		&:focus,
		&.k-chip-focused {
			background-color: $info-focused;
		}
	}
}
kendo-upload.item-upload {
	border: none !important;
	.k-upload-button {
		width: 100%;
		display: block;
		padding: 0px !important;
		opacity: 1;
		text-align: center;
		font-family:
			Open Sans,
			Arial,
			Helvetica,
			sans-serif !important;
		font-size: 14px;
		color: #1c75bc !important;
		background-color: #fff !important;
		font-weight: 600 !important;
		line-height: 2.8;
	}
	.k-dropzone {
		padding: 0px !important;
		border-color: #dddddd !important;
		border-width: 2px;
		border-style: dotted;
		border-radius: 3px;
	}
	.k-upload-files {
		display: none;
	}
	.k-actions {
		display: none;
	}
}
.full-width-upload-button {
	kendo-upload {
		.k-upload-button-wrap {
			width: 100%;
		}
	}
}

kendo-dialog.dialog-full {
	.k-content {
		padding: 0px 20px !important;
	}
	.panelbar-body {
		.k-content {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}
}
.attachment-button {
	.k-button {
		text-transform: capitalize !important;
	}
}
.no-equipment-shadow {
	.k-button {
		box-shadow: none;
	}
}
.no-equipment-shadow.k-button {
	box-shadow: none;
}
