@import '@progress/kendo-theme-material/scss/grid/_index.scss';
.k-switch-on {
	.k-switch-thumb.k-rounded-full {
		background-color: #1c75bc;
	}
	.k-switch-track.k-rounded-full {
		background-color: rgba(28, 117, 188, 0.54);
	}
}

.k-switch-off {
	.k-switch-thumb.k-rounded-full {
		background-color: #ffffff;
	}
	.k-switch-track.k-rounded-full {
		background-color: rgba(0, 0, 0, 0.38);
	}
}
