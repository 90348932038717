@import '~highlight.js/styles/github-dark-dimmed.css';
pre {
	display: flex;
	margin-top: 0;
	margin-bottom: 0;
	word-wrap: break-word;

	code {
		flex: 1;
		line-height: 1.8em;
		font-size: 14px;
		min-height: 100%;
		padding: 1em 1.2em;
		overflow-x: unset;
		overflow-y: unset;
	}

	.hljs.hljs-line-numbers {
		padding: 0 !important;
	}

	pre .hljs {
		border: none;
		transition: border ease 1s;
	}

	.hljs-ln {
		tr {
			&:first-child td {
				padding-top: 10px !important;
			}

			&:last-child td {
				padding-bottom: 10px !important;
			}
		}
	}

	table,
	tbody,
	tr,
	td {
		background: inherit;
	}

	table {
		border-collapse: separate;
		border-spacing: 0;
		background: inherit;
	}

	/* for block of numbers */
	td.hljs-ln-numbers {
		position: sticky;
		left: 0;
		user-select: none;
		text-align: center;
		color: #cccccc6b;
		border-right: 1px solid #cccccc1c;
		vertical-align: top;
		padding-right: 10px !important;
		padding-left: 10px !important;
	}

	/* for block of code */
	td.hljs-ln-code {
		padding-left: 10px !important;
	}
}

/* Master Styles */

h1,
h2,
h3,
h4,
h5 {
	color: #514e4b;
	font-family:
		Open Sans,
		Arial,
		Helvetica,
		sans-serif !important;
	margin: 0 0 16px 0;
}

h1,
h2,
h3,
h5 {
	font-weight: 600;
	/* Semi-Bold */
}

h1 {
	font-size: 30px;
}

h2 {
	font-size: 24px;
}

h3 {
	font-size: 20px;
}

h4 {
	font-size: 16px;
	font-weight: bold;
}

h5 {
	font-size: 14px;
}

.form-input > .k-label,
.form-input.k-state-empty.k-state-focused > .k-label {
	color: #000 !important;
	font-weight: bold !important;
	transform: translate(0, -6px) translate(-1px, -1.03125em) translate(-12.5%, -9.375%) scale(0.85) !important;
}

.form-input.k-state-empty > .k-label {
	transform: translate(0, 0) scale(1) !important;
}

.indent-30 {
	margin-left: 30px;
}

.indent-60 {
	margin-left: 60px;
}

body {
	margin: 0;
	background-color: #fafafa;
	font-size: 14px;
}

/* everywhere else */
* {
	font-family:
		Open Sans,
		Arial,
		Helvetica,
		sans-serif;
}

.inactiveData {
	color: gray;
}

/* Main View Wrapper */
.kpa-viewwrap {
	margin: 0px -10px 40px -10px;
}

.no-scroll {
	.k-content {
		overflow-x: hidden;
	}
}

/* Default Anchor Tag Styles */
a {
	cursor: pointer;
	cursor: hand;
	color: #1c75bc;
	text-decoration: none;
}

a:hover {
	color: #1c75bc;
	text-decoration: underline;
}

a:focus {
	color: #1c75bc;
}

a:visited {
	color: #1c75bc;
}

a i {
	margin-right: 8px;
	margin-left: 8px;
}

a svg {
	margin-right: 8px;
	margin-left: 8px;
}

/* Paragraph Tag Styling */
p {
	margin-top: 0;
	margin-bottom: 20px;
}

/* Form Tag Styling */
form {
	padding: 0 !important;
}

/* Navigation link styles */
nav a {
	padding: 5px 10px;
	text-decoration: none;
	margin-right: 10px;
	margin-top: 10px;
	display: inline-block;
	background-color: #eee;
	border-radius: 4px;
}

/*
  bug 69771
  We need to look into the use of a:link here because it's causing some links, not yet visisted, to be rendered the same as visited links.
*/
nav a:visited,
nav a:link {
	color: #607d8b;
}

nav a:hover {
	color: #039be5;
	background-color: #cfd8dc;
}

nav a.active {
	color: #039be5;
}

* {
	font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.ui-messages-detail span {
	font-weight: bold;
}

div.ui-messages {
	margin-bottom: 20px;
	margin-top: 0;
}

.kpa-dialog-button-wrap .k-button {
	color: #1c75bc;
	border-radius: 3px !important;
	font-size: 13px !important;
	margin-left: 10px;
}

.kpa-dialog-button-wrap .k-primary {
	color: #fff;
}

.kpa-dialog-button-wrap .kpa-primary {
	color: #fff;
}

.search-button .k-primary {
	width: 157px !important;
}

.standAloneBtn {
	min-width: 160px;
	padding-left: 30px !important;
	padding-right: 30px !important;
}

button.k-button-icontext span {
	margin-right: 10px;
}

button.k-button-icontext svg.svg-inline--fa {
	margin-right: 10px;
}

button.k-button .no-margin svg.svg-inline--fa {
	margin-right: 0px;
}

button.k-button-icontext:hover .k-button-icontext::before {
	background: transparent !important;
}

/* Rounded Blue Button -- Active State */
.k-button-group button.k-state-active {
	background-color: #1c75bc !important;
	color: #fff !important;
}

/* Rounded Blue Button -- Active State */
.k-button-group button.k-selected {
	background-color: #1c75bc !important;
	color: #fff !important;
}

.k-button-group,
.k-split-button {
	box-shadow: none !important;
}

.k-split-button .k-button {
	min-height: 40px;
	width: 170px;
	border: 1px solid #1c75bc;
	color: #1c75bc;
}

.k-split-button .k-button-icon {
	border-radius: 0 3px 3px 0 !important;
	border-left-width: 0 !important;
	width: 40px;
	background-color: #1c75bc !important;
	background-size: cover;
}

.k-split-button .k-button:first-child {
	border-radius: 3px 0 0 3px !important;
	background-color: #fff !important;
	border-color: #1c75bc !important;
	color: #1c75bc !important;
	border-width: 1px !important;
}

.k-split-button {
	color: #fff !important;
}

/* This needs to be moved out of this file to allow individual components to control their own layouts */
.card-foot .k-button,
.button-row .k-button {
	margin-left: 20px;
}

/* CARDS */
.card {
	padding: 30px;
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow:
		0px 2px 2px rgba(0, 0, 0, 0.2),
		0px 0px 2px rgba(0, 0, 0, 0.3);
	margin-bottom: 20px !important;
}

.card h1 {
	font-weight: 700 !important;
	margin-top: 0 !important;
	margin-bottom: 20px !important;
}

/*
  bug 69775
  This needs to be moved out of this file to allow individual components to control their own layouts.  Not every instance kpa-card makes use of card-foot.  This blanket application of 20px will cause all instances of kpa-card that doesn't make use of card-foot to have an extra 20 pixels of empty space at the bottom of the card on top of the bottom padding that already exists on kpa-card itself.
*/
.card-foot {
	margin-top: 20px;
}

.card h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
	margin-top: 0 !important;
}

.card-list-wrap {
	border-radius: 3px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
	margin-bottom: 20px !important;
}

.card-list-wrap .card {
	box-shadow: none;
	border-radius: 0 !important;
	border-top: 0;
	margin-bottom: 0 !important;
}

.card-list-wrap .card:first-child {
	border-top: 1px solid #ddd;
	border-radius: 3px 3px 0 0 !important;
}

.card-list-wrap .card:last-child {
	border-radius: 0 0 3px 3px !important;
}

.card-foot {
	margin-top: 20px;
}

.card-foot .k-button,
.button-row .k-button {
	margin-left: 20px;
}

/* GRID */
.card-grid {
	padding: 0 !important;
}

.kpa-dialog-button-wrap {
	text-align: right;
	margin-right: 0;
}

.k-window {
	border-radius: 3px !important;
	box-shadow:
		0px 1px 2px rgba(0, 0, 0, 0.2),
		0px 0px 25px 8px rgba(0, 0, 0, 0.3) !important;
}

.k-overlay {
	opacity: 0.75 !important;
}

.k-i-filter-clear::before {
	content: '\f00d' !important;
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
}

.k-filtercell-operator .k-dropdown-operator {
	display: none;
}

.k-filtercell-wrapper {
	position: relative;
}

/* BADGES */
.kpa-badge-mini,
.kpa-badge {
	border-radius: 40px;
	color: #fff;
	padding: 3px 11px 2px 9px;
	font-size: 14px;
	font-weight: 600;
	display: table;
	vertical-align: middle;
	margin-bottom: 3px;
}

.kpa-badge-mini {
	padding: 3px 5px 2px 3px;
	font-weight: 700;
	min-width: 26px;
	min-height: 26px;
	text-align: center;
	box-sizing: border-box;
}

.kpa-badge i {
	font-size: 21px;
	vertical-align: middle;
	font-weight: 300 !important;
	margin: 0 4px 0 -5px;
	min-height: 22px;
}

.kpa-badge svg {
	font-size: 21px;
	vertical-align: middle;
	font-weight: 300 !important;
	margin: 0 4px 0 -5px;
	min-height: 22px;
}

.kpa-badge-mini-green,
.kpa-badge-green {
	background-color: #6eb91e;
}

.kpa-badge-mini-orange,
.kpa-badge-orange {
	background-color: #ff7e0a;
}

.kpa-badge-mini-gray,
.kpa-badge-gray {
	background-color: #e3e3e3;
	color: #514e4b;
}

/* Wide Grid Scroll Fix */
.grid-wide .k-grid-aria-root {
	overflow-x: scroll !important;
}

.grid-wide .k-grid-header,
.grid-wide .k-grid-container {
	min-width: 1500px;
}

#gridClientLocationDetails.grid-wide .k-grid-aria-root {
	overflow-x: auto !important;
}

#gridClientLocationDetails.grid-wide .k-grid-header {
	min-width: 600px;
}

#gridClientLocationDetails.grid-wide .k-grid-container {
	min-width: 600px;
	height: 500px;
}

#gridClientLocationDetails.grid-wide .k-grid-header .k-header {
	border-left-width: 0px;
}

#gridClientLocationDetails .k-grid td {
	padding: 0px !important;
}

.grid-wide .k-grid-content {
	overflow-y: auto !important;
}

/* OTHER */
.layout-content {
	background: #fafafa;
}

.k-form-field {
	width: 100%;
}

.k-tabstrip .k-content {
	padding: 25px 0 20px 0 !important;
}

li.k-item:focus {
	/*
    CSS fix for Chrom(e|ium) accessibility selector
    https://blog.chromium.org/2020/03/updates-to-form-controls-and-focus.html
  */
	outline: none;
}

.k-checkbox:checked + .k-checkbox-label::after,
.k-checkbox:indeterminate + .k-checkbox-label::after {
	background-color: #1c75bc !important;
	border-color: #1c75bc !important;
}

.k-checkbox:checked + .k-checkbox-label::before {
	background-color: #1c75bc !important;
	border-color: #1c75bc !important;
}

.k-pager-wrap {
	background-color: #f5f5f5 !important;
}

.k-i-filter-clear::before {
	content: '\f00d' !important;
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
}

.k-filtercell-operator .k-dropdown-operator {
	display: none;
}

.k-filtercell-wrapper {
	position: relative;
}

.k-filtercell:not(kendo-grid-date-filter-cell) .k-filtercell-wrapper::after {
	content: '\f002' !important;
	font-family: 'Font Awesome 5 Pro';
	font-size: 19px;
	font-weight: 900;
	height: 36px;
	position: absolute;
	right: 0;
	text-align: center;
	width: 36px;
	z-index: 1;
}

.k-filtercell .k-i-calendar {
	color: black;
}

.kpa-upload {
	position: relative;
}

.kpa-upload kendo-upload {
	height: 100%;
	width: 100%;
	z-index: 2;
	background: transparent;
}

.k-upload .k-dropzone {
	position: relative;
	height: 100%;
	width: 100%;
	background: transparent;
}

.kpa-upload .k-upload .k-upload-button {
	margin: 0;
	height: 100%;
	width: 100%;
	background: transparent;
	opacity: 0;
}

.k-widget.k-upload.k-header {
	border-width: 0px;
}

.k-widget.k-upload .k-action-buttons {
	display: none !important;
}

.k-pager-info {
	order: 8;
}

.k-pager-sizes {
	order: 9;
}

.k-notification-group {
	z-index: 100;
}

.k-notification-wrap > .k-notification-content {
	font-family: Open Sans;
	font-size: 14px;
	color: #ffffff;
	font-weight: 700;
	text-decoration: none solid rgb(255, 255, 255);
}

.k-notification-success {
	border-color: #6eb91e !important;
	border-radius: 3px;
	background-color: #6fba1e !important;
	background-size: cover;
}

.k-icon {
	font-size: 15px;
}

.k-notification-wrap > .k-icon {
	margin-right: 11px !important;
}

.platform-logo {
	background-image: url('/assets/kpa-menu-logo.png');
}

.kpa-nav .mat-tree-node-icon {
	width: 26px !important;
	min-width: 19px !important;
}

.k-dateinput + .k-select,
.k-input + .k-select {
	margin-right: 8px;
}

#dg_AccessListLocations .card-grid {
	box-shadow: none !important;
}

.k-dropdown[disabled=''] {
	pointer-events: auto !important;
}

.kpa-dropdown-btn-menu {
	width: 100%;
	text-align: right;
}

.kpa-dropdown-btn-menu .k-split-button .k-button {
	background-color: #fff;
	border: 1px solid #1c75bc;
	color: #1c75bc !important;
}

.kpa-dropdown-btn-menu .k-split-button .k-button:first-child {
	padding: 7px 14px !important;
	font-size: 14px;
	font-weight: bold !important;
	display: inline-block;
	width: auto;
}

.kpa-dropdown-btn-menu .k-split-button .k-button:last-of-type {
	background-color: #1c75bc;
	border: 1px solid #1c75bc;
	color: #fff !important;
	max-width: 40px;
}

.kpa-dropdown-btn-menu .k-split-button .k-button-icon .k-icon::before {
	color: #fff !important;
}

.kpa-manage-employee-dropdown {
	min-width: 200px;
}

.kpa-grid tr.k-grid-norecords {
	display: table-row;
}

.kpa-grid tr.k-grid-norecords > td {
	text-align: left;
}

.k-grid-ignore-click {
	pointer-events: none !important;
}

.required-field:not(.k-invalid) {
	background-color: #e8f5ff !important;
}

.required-field:not(.required-field > .required-field) {
	background-color: #e8f5ff !important;
}

.required-field-error:not(.required-field-error > .required-field-error),
.required-field.k-state-invalid:not(.required-field > .required-field) {
	background-color: rgba(237, 81, 55, 0.08) !important;
}

.noBorderShaddowCard {
	box-shadow: none !important;
	border: 1px solid #dddddd !important;
}

/* new changes for theming */

.k-grid .k-grid-header .k-filter-row .k-filtercell .k-filtercell-operator {
	margin-left: 0;
	width: 0;
}

.k-grid .k-grid-header .k-filter-row .k-filtercell:not(kendo-grid-date-filter-cell) .k-filtercell-wrapper::after {
	content: '\f002';
	font-family: 'Font Awesome 5 Pro';
	font-size: 20px;
	font-weight: 300;
	line-height: 36px;
	position: absolute;
	right: 0;
	text-align: center;
	width: 30px;
}

.k-grid .k-grid-header .k-filter-row .k-filtercell .k-filtercell-wrapper > .k-textbox {
	padding-right: 36px;
}

.k-grid .k-grid-header .k-filter-row .k-filtercell .k-filtercell-operator > .k-button {
	position: absolute;
	right: 0;
	width: 30px;
	height: 30px;
	background-color: #1c75bc;
	z-index: 2;
}

.k-filtercell-operator {
	align-items: center;
	display: flex;
	position: absolute;
	right: 0;
	width: 30px;
	height: 30px;
	background-color: #1c75bc;
	z-index: 2;
}

.k-filtercell-operator > .k-button {
	background-color: #1c75bc;
	border: 1px solid #1c75bc;
}

.k-svg-i-filter-clear {
	color: #ffffff;
}

.k-grid .k-grid-header .k-filter-row .k-button .k-icon.k-i-filter-clear {
	color: white;
}

.k-grid .k-grid-header .kpa-grid-select-header {
	overflow: visible;
}

.k-grid .k-grid-header .kpa-grid-select-header .k-checkbox {
	position: absolute;
	top: calc(100% + 28px);
	left: 50%;
	margin-top: -9px;
	margin-left: -9px;
}

.k-grid .k-grid-content .kpa-grid-select-cell {
	text-align: center;
}

.k-panelbar > .k-item {
	border: none !important;
	box-shadow: none !important;
}

.dp-container:hover .k-picker-wrap,
.dp-container:focus-within .k-picker-wrap {
	background-color: inherit !important;
}

.k-grid .k-grid-header .k-filter-row .k-filtercell .k-multiselect-wrap {
	flex-wrap: nowrap;
}

.k-grid .k-grid-header .k-filter-row .k-filtercell .k-multiselect-wrap > :first-child {
	display: block;
	flex-basis: 0px;
	max-width: calc(100% - 25px);
}

.k-grid .k-grid-header .k-filter-row .k-filtercell .k-multiselect-wrap > .k-searchbar {
	float: none;
	flex-basis: 25px;
}

.k-grid .k-grid-header .k-filter-row .k-filtercell .k-multiselect-wrap .k-button {
	max-width: 100%;
}

.k-grid .k-grid-header .k-filter-row .k-filtercell .k-multiselect-wrap .k-button > span:first-child {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.k-checkbox-label::before {
	top: 1px !important;
}

.k-textbox-container.k-textarea-wrapper.k-state-empty > .k-label:not(.k-state-focused .k-label) {
	top: 15.875px;
}

.contacts {
	.k-textbox-container.k-textarea-wrapper.k-state-empty > .k-label:not(.k-state-focused .k-label) {
		top: 0px !important;
	}
}

.vendor-management,
.contacts-form {
	.invalid-feedback {
		margin-top: 5px !important;
	}
}

.text-yellow {
	color: #feb433;
	color: #feb433;
}

.notifications-container {
	.k-grid th {
		border-left-color: transparent;
		text-align: center;
	}

	.actions > button {
		margin-right: 12px;
	}

	.k-icon.k-clear-value.k-i-close {
		display: none !important;
	}

	.k-multiselect .k-multiselect-wrap .k-button {
		color: white !important;
		background-color: #1c75bc !important;
		font-size: 14px !important;
	}

	.k-grid-header {
		padding: 0px !important;
	}

	.textCenter {
		display: block !important;
		text-align: center !important;
	}

	kendo-pager {
		display: none;
	}
}
