$checkbox-size: 20px;
$checkbox-icon-size: 14px;
$radio-size: 20px;
$checkbox-label-margin-x: 10px;
$radio-label-margin-x: 10px;
$textarea-bg: transparent;
$textarea-hover-bg: transparent;

@import '@progress/kendo-theme-material/scss/input/_index.scss';
@import '@progress/kendo-theme-material/scss/checkbox/_index.scss';
@import '@progress/kendo-theme-material/scss/radio/_index.scss';
@import '@progress/kendo-theme-material/scss/textbox/_index.scss';
@import '@progress/kendo-theme-material/scss/textarea/_index.scss';

.k-textarea {
	background-color: transparent;
	&:focus,
	&:focus-within,
	&.k-state-focus,
	&.k-state-focused {
		box-shadow: none;
	}
}

kendo-textbox-container.no-margin-bottom {
	margin-bottom: 0px !important;
}

.k-textbox {
	background: none;
}

.k-datepicker {
	background-color: transparent;
}

.k-autocomplete {
	background-color: transparent;
}

.k-maskedtextbox {
	background-color: transparent;
}

.k-numerictextbox {
	background-color: transparent;
}
.k-multiselect {
	background-color: transparent;
}

textarea[kendoTextArea].hide-underline {
	border: none; /* Removes the underline (border) */
}

kendo-maskedtextbox.hide-underline {
	border: none; /* Removes the visible border */
}
