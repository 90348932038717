$kendo-grid-hovered-bg: inherit;
$kendo-grid-sticky-hovered-bg: initial;
$kendo-grid-selected-bg: rgba($primary, 0.16);
$kendo-grid-sticky-selected-bg: $kendo-grid-selected-bg;
$kendo-grid-text: $base-text;
$kendo-grid-header-text: $kendo-grid-text;
$kendo-grid-header-padding-y: 6px;
$kendo-grid-kendo-header-font-size: 14px;
$kendo-grid-sticky-header-border: rgba(0, 0, 0, 0.12);
$kendo-grid-sorting-indicator-text: $base-text;
$kendo-grid-header-bg: #f5f5f5;
$kendo-toolbar-bg: #f5f5f5;
$kendo-pager-bg: #f5f5f5;
$kendo-pager-text: $base-text;
$kendo-pager-number-text: $kendo-pager-text;
$kendo-pager-number-hover-bg: rgba($kendo-pager-text, 0.08);
$kendo-pager-number-focus-bg: rgba($kendo-pager-text, 0.12);
$kendo-pager-number-selected-bg: $primary;
$kendo-pager-number-selected-text: white;

@import '@progress/kendo-theme-material/scss/grid/_index.scss';

.k-grid {
	box-shadow: $container-shadow;

	.k-grid-header-locked,
	.k-grid-content-locked {
		box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.1);
	}

	.k-grid-content-locked {
		z-index: 1;
	}

	.k-grid-header {
		th {
			font-weight: 600;
		}

		td {
			background-color: $kendo-grid-header-bg;
			color: $kendo-grid-header-text;
		}

		tr {
			background-color: $kendo-grid-header-bg;
			color: $kendo-grid-header-text;
		}
	}

	.k-grid-content,
	.k-grid-content-locked {
		.k-table {
			td {
				background-color: #ffffff !important;
				color: #514e4b;
			}

			tr.k-selected {
				td {
					background-color: rgba(28, 117, 188, 0.16) !important;
					color: #000000;
				}
			}
		}
	}

	.k-filter-row {
		> td {
			border-left-color: $kendo-grid-border;
		}
	}

	.k-grid-pager {
		justify-content: flex-end;

		.k-pager-info {
			text-align: left;
			justify-content: flex-start;
			order: -1;
		}

		.k-pager-sizes > select {
			width: auto;
			background: transparent;
			border-radius: 0;
			border-top: none;
			border-left: none;
			border-right: none;
			border-bottom: $kendo-pager-text 1px solid;
			font-size: inherit;
			color: inherit;
		}

		.k-pager-nav {
			color: #514e4b;

			.k-icon::before {
				@include font-awesome-light;
			}

			.k-i-seek-w::before {
				content: '\f053';
				padding-left: 2px;
				border-left: 1px solid currentColor;
			}

			.k-i-caret-alt-to-left::before {
				content: '\f053';
				padding-left: 2px;
				border-left: 1px solid currentColor;
			}

			.k-i-arrow-w::before {
				content: '\f053';
			}

			.k-i-caret-alt-left::before {
				content: '\f053';
			}

			.k-i-seek-e::before {
				content: '\f054';
				padding-right: 2px;
				border-right: 1px solid currentColor;
			}

			.k-i-caret-alt-to-right::before {
				content: '\f054';
				padding-right: 2px;
				border-right: 1px solid currentColor;
			}

			.k-i-arrow-e::before {
				content: '\f054';
			}

			.k-i-caret-alt-right::before {
				content: '\f054';
			}
		}

		.k-pager-nav.k-button.k-selected {
			background-color: #1c75bc !important;
			color: #ffffff !important;
		}
	}
}

.k-grid-header-menu {
	.k-i-more-vertical {
		color: black;
	}
}

.k-filtercell {
	.k-i-calendar {
		color: black !important;
	}
}

.k-panelbar,
.k-card,
.k-grid {
	.k-grid {
		box-shadow: none;
	}
}

kendo-grid-date-filter-cell.hide-search-icon .k-filtercell-wrapper::after {
	display: none;
}

.checkbox-cell.kpa-grid-select-cell {
	text-align: center;
}

.k-grid-table {
	tr.k-grid-norecords > td {
		text-align: left !important;
	}
}

.widget-03,
.widget-06,
.widget-13 {
	display: flex;
	flex-direction: column;
	height: 100%;

	.widget-gauge {
		display: flex;
		justify-content: center;
		height: 100%;
		position: relative;
		top: 28%;

		kendo-arcgauge {
			height: 100px;

			.k-chart-surface > {
				margin: 0 auto;
			}

			.k-arcgauge-label {
				color: #514e4b;
				font-size: 54px;
			}
		}
	}
}

.full {
	height: 100%;
	width: 100%;
}

.primary-category {
	text-anchor: middle;
}

.primary-value {
	text-anchor: middle;
	font-weight: normal;
	font-size: 50px;
}

.secondary-category {
	text-anchor: start;
	font-weight: bold;
	font-size: 8pt;
}

.secondary-value {
	text-anchor: end;
	font-weight: bold;
	font-size: 8pt;
}

.location-summary,
.grid {
	.k-column-title {
		white-space: normal !important;
	}

	.k-filter-row {
		display: none;
	}
}

.grid {
	.k-column-title {
		white-space: normal !important;
	}

	.k-filter-row {
		display: none;
	}

	.k-progress-status {
		left: 0 !important;
		top: 15px;
		position: absolute;
	}

	.small-value {
		.k-progress-status {
			color: #424242 !important;
		}
	}

	.large-value {
		.k-progress-status {
			color: #fff !important;
		}
	}

	.k-selected {
		border-color: #6ebc2e;
		color: #6ebc2e;
		background-color: #6ebc2e;
	}

	.k-progressbar {
		background-color: #f5f5f5 !important;
	}
}

.k-chip-solid-base {
	border-color: #ebebeb;
	color: #ebebeb;
	background-color: #1c75bc !important;
	border-radius: 5em;
}

.dynamic-form {
	.widget-donut-percent {
		color: #5d5b58 !important;
	}

	.k-tilelayout .k-card {
		border-width: 0px;
	}

	.field {
		padding-right: 0px !important;
		width: 100% !important;
	}

	.field-container {
		padding-right: 0px !important;
	}
}

.no-paging {
	.k-grid-pager {
		display: none;
	}
}

.no-paging {
	.k-pager {
		display: none;
	}
}
