/// Lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

/// Darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

@mixin font-awesome {
	font-family: 'Font Awesome 5 Pro';
}

@mixin font-awesome-light {
	@include font-awesome;
	font-weight: 300;
}

$base-theme: Material;
$skin-name: KPA;
$swatch-name: Material;
$border-radius: 3px;
$theme-type: light;
$base-text: #514e4b;
$primary: #1c75bc;
$primary-contrast: white;
$primary-hovered: tint($primary, 16%);
$primary-focused: tint($primary, 16%);
$primary-darker: shade($primary, 16%);
$secondary: #00ac69;
$secondary-darker: shade($secondary, 16%);
$font-family:
	Open Sans,
	sans-serif;

$input-padding-x: 15px;
$input-font-size: 14px;
$input-default-width: 100%;

$info: #003e6a;
$info-focused: tint($info, 16%);
$info-hovered: tint($info, 16%);
$success: #6eb91e;
$success-focused: tint($success, 16%);
$success-hovered: tint($success, 16%);
$warning: #feaf25;
$warning-focused: tint($warning, 16%);
$warning-hovered: tint($warning, 16%);
$error: #ed5137;
$error-focused: tint($error, 16%);
$error-hovered: tint($error, 16%);

$container-shadow:
	0 2px 2px 0 rgba(0, 0, 0, 0.2),
	0 0 2px 0 rgba(0, 0, 0, 0.3);
$popup-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

@import '@progress/kendo-theme-material/scss/dataviz/_index.scss';
@import '@progress/kendo-theme-material/scss/tilelayout/_index.scss';
@import '@progress/kendo-theme-material/scss/stepper';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://pro.fontawesome.com/releases/v5.8.1/css/fontawesome.css?x=platform');
@import url('https://pro.fontawesome.com/releases/v5.8.1/css/light.css?x=platform');

@import './app/shared/kendo/buttons/theme/kpa-buttons-theme.scss';
@import './app/shared/kendo/label/theme/kpa-label-theme.scss';
@import './app/shared/kendo/inputs/theme/kpa-inputs-theme.scss';
@import './app/shared/kendo/tooltip/theme/kpa-tooltip-theme.scss';

@import './app/shared/kendo/layout/theme/kpa-layout-theme.scss';
@import './app/shared/kendo/dropdowns/theme/kpa-dropdowns-theme.scss';
@import './app/shared/kendo/date-inputs/theme/kpa-date-inputs-theme.scss';
@import './app/shared/kendo/grid/theme/kpa-grid-theme.scss';
@import './app/shared/kendo/switch/theme/kpa-switch-theme.scss';

// TBD Library Imports
@import '@progress/kendo-theme-material/scss/notification/_index.scss';
@import '@progress/kendo-theme-material/scss/progressbar/_index.scss';
@import '@progress/kendo-theme-material/scss/listview/_index.scss';
@import '@progress/kendo-theme-material/scss/dialog/_index.scss';
@import '@progress/kendo-theme-material/scss/upload/_index.scss';
@import '@progress/kendo-theme-material/scss/ripple/_index.scss';

@import '@progress/kendo-theme-material/scss/dataviz/_index.scss';
@import '@progress/kendo-theme-material/scss/grid/_index.scss';
@import '@progress/kendo-theme-material/scss/drawer/_index.scss';

body {
	font-size: 14px;
	color: $base-text;

	a,
	a:link,
	a:visited {
		color: $primary;
		text-decoration: none;
	}

	a:hover,
	a:active {
		color: $primary;
		text-decoration: underline;
	}

	// Real consistency problems here, uncertain from where
	::selection {
		color: $primary-contrast !important;
		background-color: $primary !important;
	}
}

.k-dialog-content {
	white-space: pre-wrap;
}
