$tooltip-text: $base-text;
$tooltip-bg: #f5f5f5;
$tooltip-font-size: 14px;
$tooltip-padding-x: 10px;
$tooltip-padding-y: 10px;
$tooltip-border-radius: 2px;
$tooltip-callout-size: 17px;

// Tooltip
@import '@progress/kendo-theme-material/scss/tooltip/_index.scss';

$tooltip-callout-width: 17px;
$tooltip-callout-height: 16px;

.white-tooltip {
	.k-tooltip {
		background-color: #f5f5f5;

		.k-i-x.k-icon {
			color: #000000;
		}
	}
}

.k-tooltip-wrapper {
	.k-tooltip {
		box-shadow: none;

		.k-callout {
			border: none;
			transform: none !important;

			&::before,
			&::after {
				top: 0;
				left: 0;
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				display: block;
			}

			&::before {
				background-color: $tooltip-bg;
				z-index: 2;
			}

			&::after {
				background-color: rgba(0, 0, 0, 0.1);
				filter: blur(4px);
				z-index: 1;
			}

			&.k-callout-n,
			&.k-callout-s {
				width: $tooltip-callout-width;
				height: $tooltip-callout-height;
			}

			&.k-callout-n {
				bottom: 100%;
				top: auto;

				&::before {
					clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
				}

				&::after {
					display: none;
				}
			}

			&.k-callout-s {
				bottom: auto;
				top: 100%;

				&::before,
				&::after {
					clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
				}

				&::after {
					top: 3px;
				}
			}

			&.k-callout-e,
			&.k-callout-w {
				margin-top: #{$tooltip-callout-size * -0.5};
				width: $tooltip-callout-height;
				height: $tooltip-callout-width;

				&::after {
					top: 3px;
				}
			}

			&.k-callout-w {
				left: auto;
				right: 100%;
				// Inexplicable 1px jog is back from the dead
				margin-right: -0.5px;

				&::before,
				&::after {
					clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
				}
			}

			&.k-callout-e {
				right: auto;
				left: 100%;

				&::before,
				&::after {
					clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
				}
			}
		}
	}
}
