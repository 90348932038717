$multiselect-token-opacity: 1;
$icon-opacity: 1;
$multiselect-token-text: white;
$multiselect-token-hover-text: $multiselect-token-text;
$multiselect-button-focused-bg: $primary-focused;
$multiselect-token-focused-opacity: 1;
$multiselect-token-focused-text: $multiselect-token-text;
$multiselect-remove-text: $multiselect-token-text;
$multiselect-remove-bg: transparent;
$multiselect-remove-hover-text: $multiselect-token-text;
$multiselect-remove-hover-bg: transparent;
$multiselect-remove-focused-text: $primary-focused;
$multiselect-remove-focused-bg: $multiselect-token-text;
$autocomplete-hovered-bg: transparent;
$autocomplete-focused-bg: transparent;
$autocomplete-invalid-bg: transparent;

@import '@progress/kendo-theme-material/scss/autocomplete/_index.scss';
@import '@progress/kendo-theme-material/scss/combobox/_index.scss';
@import '@progress/kendo-theme-material/scss/dropdownlist/_index.scss';
@import '@progress/kendo-theme-material/scss/multiselect/_index.scss';

.dropdown-ellipses-cutoff
{
	.k-popup {
		kendo-button-list {
		max-width: 250px !important;
		}
	}
}

.k-popup {
	.k-list .k-list-item {
		&.k-selected {
			background-color: rgba(28, 117, 188, 0.16) !important;
			color: #000000 !important;
		}
		&.k-focus {
			background-color: rgba(28, 117, 188, 0.16) !important;
			color: #000000 !important;
		}
	}

	kendo-button-list {
		.k-item.k-menu-item {
			font-size: 14px;
			&:hover {
				background-color: rgba(28, 117, 188, 0.16) !important;
				color: #000000 !important;
			}
			&:active {
				color: #000000 !important;
				background-color: rgba(28, 117, 188, 0.16) !important;
			}
			.k-menu-link {
				white-space: normal;
			}
		}
		.k-link:active {
			color: #000000 !important;
			background-color: rgba(28, 117, 188, 0.16) !important;
		}
	}

	kendo-list {
		.k-item.k-menu-item {
			font-size: 14px;
			&:hover {
				background-color: rgba(28, 117, 188, 0.16) !important;
				color: #000000 !important;
			}
			&:active {
				color: #000000 !important;
				background-color: rgba(28, 117, 188, 0.16) !important;
			}
		}
		.k-link:active {
			color: #000000 !important;
			background-color: rgba(28, 117, 188, 0.16) !important;
		}
	}
}

.k-dropdown .k-select .k-icon.k-i-arrow-s::before {
	@include font-awesome-light;
	content: '\f078';
}

.k-dropdown .k-select .k-icon.k-i-chevron-down::before {
	@include font-awesome-light;
	content: '\f078';
}

.k-form-field {
	.k-floating-label-container {
		.k-dropdown-wrap > .k-select {
			position: relative;
			bottom: calc($input-padding-x / 2);
		}
	}
}

.k-multiselect {
	.k-multiselect-wrap {
		padding-left: $input-padding-x;

		.k-searchbar {
			.k-input {
				padding-left: 0;
			}
		}

		.k-button {
			font-size: 14px;
			font-weight: normal;

			.k-select {
				height: 16px;
				width: 16px;
				align-self: center;
				margin-right: -0.5em;
				justify-content: center;

				.k-icon {
					transform: scale(1);
					font-size: 14px;
				}
			}

			&,
			& .k-select .k-icon {
				color: $multiselect-token-text;
			}

			&:active,
			&.k-state-active {
				box-shadow: none;
			}

			&:hover {
				&::before {
					background: $primary-hovered;
				}

				.k-select {
					color: $multiselect-token-hover-text;
					background: transparent;

					&:hover {
						background: white;

						.k-icon {
							color: $primary-hovered;
						}
					}
				}
			}

			&.k-state-focused {
				&::before {
					background: $primary-focused;
				}
			}
		}
	}
}

kendo-dropdownbutton.icon-right {
	width: 100% !important;

	.k-button {
		display: flex !important;
		width: 100% !important;

		.fa-ellipsis-v {
			order: 2 !important;
		}

		.fa-ellipsis-vertical {
			order: 2 !important;
		}

		.k-button-text {
			color: #1c75bc !important;
			order: 1 !important;
			flex: 11 !important;
			text-align: left !important;
		}
	}
}

tr.k-selected {
	td {
		div {
			kendo-dropdownbutton.icon-right {
				.k-button-text {
					color: #ffffff !important;
				}
			}
		}
	}
}
#grid_IncidentSearch_IncidentDateFilter .k-filtercell-wrapper::after {
	display: none !important;
}
kendo-dialog.view-item {
	.k-dialog-titlebar {
		padding-bottom: 2px;
	}
}
kendo-dropdownlist.hide-icon {
	.k-i-arrow-s {
		display: none;
	}

	.k-i-chevron-down {
		display: none;
	}
}

.content-details {
	.card-solid {
		margin-bottom: 0px !important;
		margin-right: 5px !important;
	}
}

.kendo-dropdown {
	background: none;
}

.k-dropdownlist {
	background-color: transparent;
}

.k-checkbox:checked {
	border-color: #1c75bc;
	color: white;
	background-color: #1c75bc;
}
